import { Box, Checkbox, TextField, Typography } from "@mui/material"

import { Field, useFormikContext } from "formik"
import { SignUpFormValues } from "../../../store/reducers/signup"
import ReCAPTCHA from "react-google-recaptcha"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { useState } from "react"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import { useAppDispatch } from "store/hooks"
import { VerificationEmailSent } from "./VerificationEmailSent"
import { sendVerificationEmail } from "../../../store/reducers/signup"
import { openSnackbar } from "features/snackbar/snackbarSlice"
import Loading from "@mobilemind/common/src/components/Loading"

export const SignUpUserInformation = () => {
  const { errors, isValid, touched, values, isSubmitting } =
    useFormikContext<SignUpFormValues>()
  const [isLoading, setIsLoading] = useState(false)

  const [captchaSuccess, setCaptchaSuccess] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [domainError, setDomainError] = useState(false)

  const dispatch = useAppDispatch()
  const canContinue =
    touched.username && isValid && captchaSuccess && values.confirmGoogleMS

  if (domainError) {
    return (
      <Box
        className="sign-up-user-form domain-error"
        sx={{ display: "contents" }}
      >
        <Box className="learning-buddy-animation" sx={{ marginBottom: 5 }}>
          <LearningBuddyMessage
            message={<p dangerouslySetInnerHTML={{ __html: domainError }} />}
          />
        </Box>
      </Box>
    )
  }

  if (!emailSent) {
    return (
      <Box className="sign-up-user-form" sx={{ display: "contents" }}>
        <Box className="learning-buddy-animation" sx={{ marginBottom: 5 }}>
          <LearningBuddyMessage
            message={
              <>
                <strong>Welcome to MobileMind!</strong> We're excited to have
                you join our community.
                <br />
                Fill out the form below to get started.
              </>
            }
          />
        </Box>
        <Box sx={{ display: "flex", marginBottom: 4 }}>
          <Field
            sx={{ flex: 1 }}
            as={TextField}
            name="username"
            label="Username"
            required
            fullWidth={true}
            error={touched.username && !!errors.username}
            disabled={isSubmitting}
            autoFocus={true}
          />
        </Box>

        <Field
          as={TextField}
          name="email"
          label="Email"
          required
          fullWidth={true}
          error={touched.email && !!errors.email}
          disabled={isSubmitting}
        />

        <Box sx={{ marginTop: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", marginLeft: -2 }}>
            <Field
              as={Checkbox}
              required
              name="confirmGoogleMS"
              label="This is a Google/Microsoft email"
            />

            <Typography sx={{ fontSize: 14, marginLeft: -1 }}>
              This is a Google/Microsoft email
            </Typography>
          </Box>
          <Typography sx={{ fontSize: 12, opacity: 0.6, textAlign: "left" }}>
            A Google or Microsoft account is necessary to sign in. A school or
            district email is preferred, but it must be either Google or
            Microsoft.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", marginBlock: 4, justifyContent: "center" }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            // On success
            onChange={() => {
              setCaptchaSuccess(true)
            }}
          />
        </Box>
        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Loading message="Just a moment..." />
          </Box>
        )}
        {canContinue && !isLoading && (
          <ButtonLarge
            onClick={async () => {
              setIsLoading(true)
              // Handle sending email
              dispatch(sendVerificationEmail(values)).then((response) => {
                // Handle errors
                if (response.payload?.errors && response.payload?.errors[0]) {
                  const error = response.payload.errors[0].detail
                    .replaceAll("mail: ", "")
                    .replaceAll("name: ", "")
                    .replaceAll("mail.0.value: ", "")

                  // Determine to show the domain error message
                  if (error.includes("email domain")) {
                    setDomainError(error.replace("Reach", "<br/><br/>Reach"))
                  } else {
                    dispatch(openSnackbar({ message: error }))
                  }
                } else {
                  // Otherwise show the confirmation message
                  setEmailSent(true)
                }
              })
              setIsLoading(false)
            }}
          >
            Let's Go!
          </ButtonLarge>
        )}
      </Box>
    )
  }

  return <VerificationEmailSent />
}
